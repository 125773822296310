export const environment: any = {
  production: false,
  staging: true,
  apiUrl: 'https://qpkgmyqsdv-api.fabrika.me/v1',
  baseApiUrl: 'https://qpkgmyqsdv-api.fabrika.me',
  websiteUrl: 'https://qpkgmyqsdv.fabrika.me',
  mediaUrl: 'https://storage-dev.medialib.dev/media/',
  donationsMerchantUrl: 'https://rjweytqscd-api.fabrika.me/v1/merchants/b7ebe874-a38f-40b7-8180-39b16362e1fa',
  mailChimpEndpoint : 'https://fleka.us14.list-manage.com/subscribe/post-json?u=900bc09fcff279240663fd3f7&amp;id=bf542b4186'
};
